import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

import { shortenAddress } from './utils/transform'

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}))

export default function AddressesArray({
  addresses,
  onDelete,
}: {
  addresses: string[]
  onDelete: (address: string) => void
}) {
  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        gap: '0.5rem',
      }}
      component="ul"
    >
      {addresses.map((address, i) => {
        return (
          <ListItem key={i}>
            <Chip
              label={shortenAddress(address)}
              onDelete={() => onDelete(address)}
              sx={{ backgroundColor: 'var(--dark, #222528)', color: 'white' }}
            />
          </ListItem>
        )
      })}
    </Paper>
  )
}
