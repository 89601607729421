import { Stack } from '@mui/material'
import { useWallet } from '@tronweb3/tronwallet-adapter-react-hooks'
import { API_URL } from 'api/api'
import { Button, Dots, TextInput, TransactionLoader, Web3ConnectBtn } from 'components'
import { ApproveCheckerErcSale, ApproveCheckerTrcSale } from 'components/Approval/ApproveTx'
import { AmountInputMultichainToken } from 'components/blocks/AmountInput/AmountInput'
import { TokenSymbol } from 'components/blocks/AmountInput/TokenSymbol'
import { useHandleChainSwitch } from 'components/Header/NetworkSelector'
import { LoadingAnimation } from 'components/MUI/Button'
import { WrongNetworkButton } from 'components/WarningBanner'
import {
  ARBITRUM_USDT,
  BSC_USDT,
  ETH_USDT,
  POLYGON_USDT,
  useEvmReferralsContract,
  useEvmSaleContract,
  useTronSaleAddress,
  useTronUSDTAddress,
} from 'constants/app-contracts'
import { SupportedChainId, TRON_CHAIN_ID } from 'constants/chainsinfo'
import { ZERO_HASH } from 'constants/misc'
import { BigNumber } from 'ethers'
import { useTxTemplate } from 'hooks/base/tx-template'
import { getTronProvider, useTronWebContract, walletConnectTron } from 'hooks/tronweb'
import { useActiveWeb3React } from 'hooks/web3'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAddPopup } from 'state/application/hooks'
import styled from 'styled-components'
import { ZERO } from 'utils/isZero'
import { formatDecimal } from 'utils/numberWithCommas'

import { EmailInfo } from './EmailInfo'
import { InfoBlock } from './InfoBlock'
import { TronConnectButton } from './TronInfoBar/WarningBanner'

const StyledTronConnectButton = styled(TronConnectButton)`
  background: ${({ theme }) => theme.dark} !important;
  color: ${({ theme }) => theme.light} !important;
  animation: ${(loading) => (loading ? LoadingAnimation : 'none')} 1.5s linear infinite;
  padding: 1rem 3rem;
  font-size: 1.375rem;
  width: 100%;
`

const useParticipateInSale = (
  amount: BigNumber | undefined,
  email: string,
  decimals: number,
  isCodeAvailable: boolean,
  refCode: string | undefined,
  setSuccessState: (state: any) => void
) => {
  const referralsContract = useEvmReferralsContract()
  const saleContract = useEvmSaleContract()

  const addPopup = useAddPopup()
  const actionType = `sale_${amount?.toString()}`

  const dataFunc = useCallback(
    async (showPopup?: boolean) => {
      try {
        if (!amount || amount.isZero()) {
          return
        }

        let refCodeHash = ZERO_HASH
        if (referralsContract && refCode && isCodeAvailable) {
          refCodeHash = (await referralsContract?.getReferralCodeHash(refCode)) as string
        }

        const hash = (await getApiHashFor(email)).message

        const txAddress = await saleContract?.populateTransaction.buy(amount, stringToHex(hash), refCodeHash)

        return txAddress
      } catch (e) {
        showPopup &&
          addPopup({
            msg: {
              success: false,
              title: <>Transaction Error</>,
              description: <>Can not populate transaction or not enough balance for fee</>,
            },
          })
        return undefined
      }
    },
    [referralsContract, addPopup, amount, email, saleContract, isCodeAvailable, refCode]
  )

  return useTxTemplate(
    'Sale',
    actionType,
    `Participated in sale with ${formatDecimal(amount || ZERO, 2, decimals)} tokens`,
    dataFunc,
    setSuccessState,
    'Something goes wrong'
  )
}

export async function getApiHashFor(email: string) {
  // @ts-ignore
  return fetch(API_URL + '/encode_message', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
    }),
  }).then((r) => r.json())
}

export const stringToHex = (str: string) => {
  return '0x' + str
}

export enum TX_STATE {
  SUCCESS = 'success',
  ERROR = 'error',
  UNKNOWN = 'unknown',
  PENDING = 'pending',
}

export const useSaleTokens = () => {
  const { account: evmAddress } = useActiveWeb3React()
  const { address: tronAddress } = useWallet()

  const tronUsdtAddress = useTronUSDTAddress()

  return useMemo(() => {
    return [
      {
        symbol: 'USDT' as TokenSymbol,
        address: ETH_USDT,
        chain: SupportedChainId.MAINNET,
        enabled: !!evmAddress,
      },
      {
        symbol: 'USDT' as TokenSymbol,
        address: BSC_USDT,
        chain: SupportedChainId.BNB,
        enabled: !!evmAddress,
      },
      {
        symbol: 'USDT' as TokenSymbol,
        address: POLYGON_USDT,
        chain: SupportedChainId.POLYGON,
        enabled: !!evmAddress,
        isL2: true,
      },
      {
        symbol: 'USDT' as TokenSymbol,
        address: ARBITRUM_USDT,
        chain: SupportedChainId.ARBITRUM_ONE,
        enabled: !!evmAddress,
        isL2: true,
      },
      {
        symbol: 'USDT' as TokenSymbol,
        address: tronUsdtAddress,
        chain: TRON_CHAIN_ID,
        enabled: !!tronAddress,
      },
    ]
  }, [tronUsdtAddress, tronAddress, evmAddress])
}

function SaleForm({
  amount,
  setAmount,
  refContract,
  refCode,
  isCodeAvailable,
  loading,
  currentPrice,
  isTronSale,
  setIsTronSale,
  decimals,
  tokenIn,
  tokens,
  setTokenIn,
  rightToken,
}: {
  amount?: BigNumber
  setAmount: (v: BigNumber) => void
  refContract?: any
  refCode?: string
  isCodeAvailable: boolean
  loading: boolean
  currentPrice: number
  isTronSale: boolean
  setIsTronSale: (v: boolean | ((prev: boolean) => boolean)) => void
  decimals: number
  rightToken: {
    symbol: TokenSymbol
    address: string
    chain: SupportedChainId
    enabled: boolean
    isL2?: boolean
  }
  tokens: {
    symbol: TokenSymbol
    address: string
    chain: SupportedChainId
    enabled: boolean
  }[]
  setTokenIn: (address: string) => void
  tokenIn?: string
}) {
  const [pending, setPending] = useState(false)
  const [success, setSuccess] = useState<TX_STATE>(TX_STATE.UNKNOWN)

  const [email, setEmail] = useState<string>('')

  const { t } = useTranslation()

  const handleChange = useCallback(
    (v: any) => {
      setAmount(v)
    },
    [setAmount]
  )

  const noValue = !amount || amount.isZero()

  const { address: tronAddress } = useWallet()
  const { account: evmAddress } = useActiveWeb3React()

  const saleAddress = useTronSaleAddress()

  const contract = useTronWebContract(saleAddress)

  const tronUsdtAddress = useTronUSDTAddress()

  const handleChainSwitch = useHandleChainSwitch()

  const handleChangeToken = useCallback(
    (v: string) => {
      if (v === TokenSymbol.usdt) {
        handleChainSwitch(SupportedChainId.XFI_TESTNET, true)
      }

      setTokenIn(v)

      if (v && v !== tokenIn) {
        setAmount(ZERO)
      }
    },
    [setTokenIn, handleChainSwitch, setAmount, tokenIn]
  )

  useEffect(() => {
    if (rightToken.address !== tronAddress) {
      handleChainSwitch(rightToken.chain, true)
    }
  }, [rightToken, handleChainSwitch, tronAddress])

  useEffect(() => {
    setIsTronSale((prev) => {
      const isTron = tokenIn === tronUsdtAddress

      if (prev === isTron) {
        return prev
      }

      return isTron
    })
  }, [tokenIn, setIsTronSale, tronUsdtAddress])

  const setSuccessState = useCallback(() => {
    setSuccess(TX_STATE.SUCCESS)
    setPending(false)
  }, [setSuccess, setPending])

  const {
    action: actionEvm,
    txInfo,
    isError,
  } = useParticipateInSale(amount, email, decimals, isCodeAvailable, refCode, setSuccessState)

  const action = useCallback(async () => {
    if (amount && email && contract) {
      setPending(true)

      let refCodeHash = ZERO_HASH
      if (refContract && refCode && isCodeAvailable) {
        refCodeHash = (await refContract?.getReferralCodeHash(refCode).call()) as string
      }

      const hash = (await getApiHashFor(email)).message

      if (isTronSale) {
        if (walletConnectTron.address) {
          try {
            // https://tronweb.network/docu/docs/Interact%20with%20contract/
            const provider = getTronProvider()
            const functionSelector = 'buy(uint256,bytes,bytes32)'
            const parameter = [
              { type: 'uint256', value: amount },
              { type: 'bytes', value: stringToHex(hash) },
              {
                type: 'bytes32',
                value: refCodeHash,
              },
            ]
            // [{"name":"amount","type":"uint256"},{"name":"purchase_data","type":"bytes"},{"name":"referralCodeHash","type":"bytes32"}]

            const unSignedTransaction = await provider.transactionBuilder.triggerSmartContract(
              saleAddress,
              functionSelector,
              {},
              parameter
            )

            // using adapter to sign the transaction
            const signedTransaction = await walletConnectTron.signTransaction(unSignedTransaction)
            // broadcast the transaction
            console.log('signedTransaction', signedTransaction, unSignedTransaction)
            await provider.trx.sendRawTransaction(signedTransaction)
          } catch (error: any) {
            setSuccess(TX_STATE.ERROR)
            throw error
          } finally {
            setPending(false)
          }

          return
        } else {
          contract
            .buy(amount, stringToHex(hash), refCodeHash)
            .send()
            .then((res: any) => {
              setSuccess(TX_STATE.SUCCESS)
            })
            .catch((error: Error) => {
              console.debug('Failed to send token', error)
              setSuccess(TX_STATE.ERROR)
              throw error
            })
            .finally(() => {
              setPending(false)
            })
        }
      } else {
        try {
          setPending(true)
          await handleChainSwitch(rightToken.chain, true)
          await actionEvm()
        } catch (e) {
          console.error(e)
          setSuccess(TX_STATE.ERROR)
        } finally {
          setPending(false)
        }
      }
    }
  }, [
    handleChainSwitch,
    rightToken,
    amount,
    email,
    contract,
    setPending,
    refCode,
    refContract,
    isCodeAvailable,
    setSuccess,
    saleAddress,
    isTronSale,
    actionEvm,
  ])

  const { chainId } = useActiveWeb3React()

  const isWrongNetwork = useMemo(() => {
    return !isTronSale && rightToken.chain !== chainId
  }, [isTronSale, rightToken, chainId])

  const ConfirmBlock = useMemo(() => {
    return (
      <>
        {noValue ? (
          <Button disabled={noValue}>{t('TokenSale.EnterAmount')}</Button>
        ) : (
          <Button onClick={action} disabled={!!pending || loading}>
            {loading ? <Dots>{t('referrals.loading')}</Dots> : t('TokenSale.Invest')}
          </Button>
        )}
      </>
    )
  }, [noValue, t, action, pending, loading])

  if (pending || success !== TX_STATE.UNKNOWN) {
    return (
      <>
        <TransactionLoader
          done={success === TX_STATE.SUCCESS}
          error={success === TX_STATE.ERROR || (!isTronSale && isError)}
        />

        <InfoBlock currentPrice={currentPrice} />

        {success && (
          <Button
            onClick={() => {
              setSuccess(TX_STATE.UNKNOWN)
              setPending(false)
            }}
            size="medium"
          >
            {t('TokenSale.close')}
          </Button>
        )}
      </>
    )
  }

  return (
    <>
      <Stack
        gap=".75rem"
        style={{
          marginBottom: '1.5rem',
        }}
      >
        <AmountInputMultichainToken
          inputValue={amount}
          setInputValue={handleChange}
          disabled={!!pending}
          rightToken={rightToken as any}
          showBalanceRow={!!tronAddress || !!evmAddress}
          decimals={decimals}
          rightTokenOptions={tokens as any[]}
          onChangeRightToken={handleChangeToken}
          showSearch={false}
          showChainIcon={true}
        />

        <TextInput
          placeholder="email@example.com"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          type="email"
          required
          value={email}
          onChange={(e: any) => {
            setEmail(e.target.value)
          }}
        />

        <EmailInfo />

        <InfoBlock currentPrice={currentPrice} />
      </Stack>

      {isTronSale && !tronAddress ? (
        <StyledTronConnectButton>{t('TokenSale.PleaseConnect')}</StyledTronConnectButton>
      ) : !evmAddress && !isTronSale ? (
        isWrongNetwork ? (
          <WrongNetworkButton as={Button} chainId={rightToken.chain} buttonText={t('WrongNetwork.button')} />
        ) : (
          <Web3ConnectBtn as={Button} theme="dark" text={t('TokenSale.PleaseConnect')} />
        )
      ) : !email ? (
        <Button disabled={true}>{t('TokenSale.PleaseEnterEmail')}</Button>
      ) : isTronSale ? (
        <ApproveCheckerTrcSale border={amount} token={tronUsdtAddress} asBtn={Button}>
          {ConfirmBlock}
        </ApproveCheckerTrcSale>
      ) : isWrongNetwork ? (
        <WrongNetworkButton as={Button} chainId={rightToken.chain} buttonText={t('WrongNetwork.button')} />
      ) : (
        <ApproveCheckerErcSale border={amount} token={rightToken.address} asBtn={Button}>
          {ConfirmBlock}
        </ApproveCheckerErcSale>
      )}
    </>
  )
}

export default memo(SaleForm)
