import { Button, TextInput, TransactionLoader } from 'components'
import { MaxButton, ModalTitle, TokenIcon } from 'components/blocks/AmountInput/styles'
import Modal from 'components/Modal'
import { t } from 'i18next'
import { type ComponentProps, useState } from 'react'
import { fullDate } from 'utils/date'

import SWA from '../../../assets/svg/swa-token.svg'
import { ClaimContainer } from '../ClaimContainer/ClaimContainer'
import { formatAmount, formatUsdValue } from '../ClaimContainer/helpers/formatValue'
import { Description } from '../styled'
import { TransactionInfo } from './TransactionInfo/TransactionInfo'
import { WarningBox } from './WarningBox'

interface ClaimModalProps extends ComponentProps<'div'> {
  isOpenFlag: boolean
  onOpenFlagChange: (isOpen: boolean) => void
}

export const ClaimModal = (props: ClaimModalProps) => {
  const { isOpenFlag, onOpenFlagChange } = props

  const [amountToClaim, setAmountToClaim] = useState('')
  const [claimWalletAddress, setClaimWalletAddress] = useState('')

  const isLoading = true

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          <TransactionLoader style={{ overflow: 'hidden' }} done={false} error={false} />
          <TransactionInfo style={{ marginTop: '1.5rem' }} />
        </>
      )
    }

    return (
      <>
        <ClaimContainer
          amountInputProps={{
            value: amountToClaim,
            onChange: (value: string) => setAmountToClaim(value),
          }}
          rightSection={
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', fontSize: '1.5rem' }}>
              <TokenIcon src={SWA} />
              <span>SWA</span>
            </div>
          }
          bottomLeftSection={
            <Description style={{ display: 'flex', alignItems: 'center', gap: '0.75rem' }}>
              {formatUsdValue(12)}
            </Description>
          }
          bottomRightSection={
            <Description style={{ display: 'flex', alignItems: 'center', gap: '0.75rem' }}>
              <span>{formatAmount(48.0, { minimumFractionDigits: 2, notation: 'compact' })}</span>
              <MaxButton onClick={() => setAmountToClaim('48.0')}>Max</MaxButton>
            </Description>
          }
        />
        <TextInput
          style={{ margin: '0.5rem 0rem' }}
          placeholder="Wallet address to withdraw"
          value={claimWalletAddress}
          onChange={(e: any) => setClaimWalletAddress(e.target.value)}
        />
        <TransactionInfo />
        <WarningBox text="Not enough funds" style={{ marginTop: '0.75rem' }} />
        <Button style={{ marginTop: '1.5rem', width: '100%' }}>Claim</Button>
      </>
    )
  }

  return (
    <Modal
      header={
        <ModalTitle>
          <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
            <TokenIcon src={SWA} />
            <span>{t('TokenClaim.TokenClaim')}</span>
          </div>
          <Description style={{ marginLeft: '2.5rem' }}>
            Next unlock date:{' '}
            <span style={{ color: 'var(--Blue, #0085FF)' }}>{fullDate(new Date().getTime(), 'dd.LL.yyyy')}</span>
          </Description>
        </ModalTitle>
      }
      isOpenFlag={isOpenFlag}
      onDismissHandler={() => onOpenFlagChange(false)}
    >
      <div style={{ margin: '1.5rem 0rem 0rem 0rem' }}>{renderContent()}</div>
    </Modal>
  )
}
