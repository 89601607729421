import { base58 } from 'ethers/lib/utils'

export type TronAddress = `T${string}`

export function isTronAddress(address: TronAddress): boolean {
  if (!address.startsWith('T')) {
    return false
  }

  try {
    const decoded = base58.decode(address.slice(1))
    console.log(decoded)
    return decoded.length === 24
  } catch {
    return false
  }
}
