import { useWallet as useTronWallet } from '@tronweb3/tronwallet-adapter-react-hooks'
import { Button, Card, CommonLayout, OutlinedButton, TextInput } from 'components'
import { ethers } from 'ethers'
import { useIsMobileDevice } from 'hooks/useIsMobileDevice'
import { useYandexMetrikaHit } from 'hooks/useYandexMetrika'
import { useActiveWeb3React } from 'hooks/web3'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { isAddress } from 'utils'
import { Address } from 'viem'

import { usePurchases } from './api/query/usePurchases'
import AddressesArray from './ChipArray'
import { ClaimModal } from './ClaimModal/ClaimModal'
import { PurchasedAmountCard } from './PurchasedAmountCard'
import { Container, Title } from './styled'
import { isTronAddress, TronAddress } from './utils/IsTronAddress'

export default function TokenClaim() {
  return <Layout />
}

const message = 'Please sign this message to verify your wallet'

const signEvmMessage = async (address?: Address) => {
  try {
    if (!window.ethereum) throw new Error('No crypto wallet found')

    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner(address)

    const signature = await signer.signMessage(message)
    console.log('Signature:', signature)

    return signature
  } catch (err) {
    console.error('Error signing message:', err)
    return ''
  }
}

const signTronMessage = async () => {
  try {
    // Make sure TronWeb is injected by TronLink
    if (!window.tronWeb) {
      throw new Error('Please install TronLink')
    }

    // Sign the message
    const signature = await window.tronWeb.trx.signMessageV2(message)
    console.log('Tron Signature:', signature)
    return signature
  } catch (error) {
    console.error('Error signing message:', error)
    throw error
  }
}

const Layout = () => {
  useYandexMetrikaHit()
  const isMobile = useIsMobileDevice()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [addresses, setAddresses] = useState<(Address | TronAddress)[]>([])
  const [inputAddress, setInputAddress] = useState<string>('')
  const [email, setEmail] = useState<string>('')

  // set wallets to addresses
  const { account: evmAddress } = useActiveWeb3React()

  const { address: tronAddress } = useTronWallet()

  useEffect(() => {
    if (tronAddress && !addresses.includes(tronAddress as Address | TronAddress)) {
      setAddresses([...addresses, tronAddress as Address | TronAddress])
    }
  }, [tronAddress])

  useEffect(() => {
    if (evmAddress && !addresses.includes(evmAddress as Address | TronAddress)) {
      setAddresses([...addresses, evmAddress as Address | TronAddress])
    }
  }, [evmAddress])

  const isValid = addresses.length > 0
  const { data, isFetching, refetch } = usePurchases(
    { email: email.length > 0 ? [email] : undefined, address: addresses },
    isValid
  )

  useEffect(() => {
    if (
      (isTronAddress(inputAddress as TronAddress) || isAddress(inputAddress)) &&
      !addresses.includes(inputAddress as Address | TronAddress)
    ) {
      setAddresses([...addresses, inputAddress as Address | TronAddress])
      setInputAddress('')
    }
  }, [addresses, inputAddress])

  return (
    <CommonLayout>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          gap: '1.25rem',
          marginTop: isMobile ? '4.5rem' : '5.5rem',
        }}
      >
        <PurchasedAmountCard amount={data?.total_stats.total_tokens_purchased || 0} isLoading={isFetching} />
        {/* <Button onClick={() => signEvmMessage()}>Sign evm message</Button>
        <Button onClick={() => signTronMessage()}>Sign tron message</Button> */}
        <Card gap="16px" style={{ width: isMobile ? '100%' : '34.75rem', padding: '2rem 1.5rem' }}>
          <Title>{t('TokenClaim.TokenClaim')}</Title>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <TextInput
              placeholder="Wallet address"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              type="text"
              required
              disabled={addresses.length >= 3}
              value={inputAddress}
              onChange={(e: any) => {
                setInputAddress(e.target.value)
              }}
            />
            {addresses.length > 0 && (
              <AddressesArray
                addresses={addresses}
                onDelete={(address) => setAddresses(addresses.filter((a) => a !== address))}
              />
            )}
            <TextInput
              placeholder="email@example.com"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              type="email"
              required
              value={email}
              onChange={(e: any) => {
                setEmail(e.target.value)
              }}
            />
          </div>
          <Button onClick={() => refetch()} disabled={!isValid}>
            Check Balance
          </Button>
          <OutlinedButton onClick={() => setIsModalOpen(true)}>Claim Tokens</OutlinedButton>
        </Card>
      </Container>
      <ClaimModal isOpenFlag={isModalOpen} onOpenFlagChange={setIsModalOpen} />
    </CommonLayout>
  )
}
