import type { ComponentProps } from 'react'

import { formatAmount } from '../../ClaimContainer/helpers/formatValue'
import { Description } from '../../styled'

type AccordionSWARowProps = ComponentProps<'div'> & {
  amount: number | string
  label: string
}

export const AccordionSWARow = (props: AccordionSWARowProps) => {
  const { className, style, amount, label, ...rest } = props
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        margin: '0rem',
        ...style,
      }}
      {...rest}
    >
      <div>{label}</div>
      <Description>
        <span style={{ color: 'var(--TEXT-100, #434647)' }}>
          {formatAmount(amount, { minimumFractionDigits: 2, notation: 'compact' })}
        </span>{' '}
        SWA
      </Description>
    </div>
  )
}
