import Skeleton from '@mui/material/Skeleton'
import { Card } from 'components'
import { TokenIcon } from 'components/blocks/AmountInput/styles'
import { useIsMobileDevice } from 'hooks/useIsMobileDevice'

import SWA from '../../assets/svg/swa-token.svg'
import { formatAmount } from './ClaimContainer/helpers/formatValue'
import { SubTitle, Title } from './styled'

type PurchasedAmountCardProps = {
  amount: number
  isLoading: boolean
}

export const PurchasedAmountCard = ({ amount, isLoading }: PurchasedAmountCardProps) => {
  const isMobile = useIsMobileDevice()

  return (
    <Card
      style={{
        padding: '1.25rem',
        height: 'fit-content',
        width: isMobile ? '100%' : '34.75rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SubTitle style={{ textTransform: 'uppercase', marginBottom: '0.75rem' }}>Purchased Amount</SubTitle>
      {isLoading ? (
        <Skeleton
          variant="rounded"
          sx={{
            fontSize: '1.875rem',
            bgcolor: 'grey.100',
            width: '10rem',
          }}
        />
      ) : (
        <Title style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          <TokenIcon src={SWA} />
          <span>{formatAmount(amount)} SWA</span>
        </Title>
      )}
    </Card>
  )
}
