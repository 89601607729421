import { useQuery } from '@tanstack/react-query'
import { API_URL } from 'api/api'
import axios from 'axios'
import { TronAddress } from 'pages/TokenClaim/utils/IsTronAddress'
import qs from 'qs'
import { Address } from 'viem'
type Params = {
  email?: string[]
  address?: (Address | TronAddress)[]
}

type Decimals = {
  usdt: number
  purchased_token: number
}

type Purchase = {
  buyer: Address
  network: string
  tokens_purchased: number
  usdt_spent: number
  purchase_data: string
  tx_hash: string
  referral_hash: string
  referral_code: string
  decimals: Decimals
}

type TotalStats = {
  total_usdt_spent: number
  total_tokens_purchased: number
}

export type PurchaseData = {
  purchases: Purchase[]
  total_stats: TotalStats
}

const getPurchases = async (params: Params) => {
  const response = await axios.get<PurchaseData>(`${API_URL}/purchases`, {
    params,
    paramsSerializer: (parameters_) => {
      return qs.stringify(parameters_, { arrayFormat: 'repeat' })
    },
  })
  return response.data
}

export const usePurchases = (params: Params, enabled = true) => {
  const data = useQuery({
    queryKey: ['purchases', params],
    queryFn: () => getPurchases(params),
    enabled,
  })
  return data
}
