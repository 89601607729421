import { WETH_XFI } from 'constants/app-contracts'
import { ITokenAsset, TOKENS } from 'hooks/useTokenAsset'
import { useMemo } from 'react'

import xfiIcon from './icons/xfi.svg'
import { TokenSymbol } from './TokenSymbol'
import { IPickerToken } from './useAmountInput'

export type IAppToken = ITokenAsset

const DEFAULT_ETH: IAppToken = {
  symbol: TokenSymbol.xfi,
  name: 'XFI',
  icon: xfiIcon,
  address: WETH_XFI,
}

export const useAppSelectedCoin = (token?: IPickerToken): IAppToken => {
  return useMemo(() => {
    if (!token || !token.symbol) {
      return DEFAULT_ETH
    }

    const foundByAddress = TOKENS.find((coin) => coin.address === token.address)

    if (foundByAddress) {
      return foundByAddress
    }

    const foundBySymbol = TOKENS.find((coin) => coin.symbol.toLowerCase() === token.symbol.toLowerCase())

    const target = foundBySymbol || ((token?.address ? { ...token, icon: '', label: '' } : DEFAULT_ETH) as IAppToken)
    return target
  }, [token])
}
