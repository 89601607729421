import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ArrowIcon from 'assets/icons/arrow-down.svg'
import type { ComponentProps } from 'react'

import { formatUsdValue } from '../../ClaimContainer/helpers/formatValue'
import { AccordionSWARow } from './AccordionSWARow'

type TransactionInfoProps = ComponentProps<'div'>

export const TransactionInfo = (props: TransactionInfoProps) => {
  const { style } = props
  return (
    <Accordion
      style={{
        borderRadius: '0.75rem',
        border: '1px solid var(--Div-Stroke, #D8ECFF)',
        backgroundColor: 'transparent',
        color: 'var(--TEXT-100, #434647)',
        boxShadow: 'none',
        margin: '0rem',
        ...style,
      }}
    >
      <AccordionSummary
        style={{ padding: '0rem 1rem', margin: '0rem' }}
        expandIcon={<img src={ArrowIcon} style={{ width: '1rem', height: '1rem' }} />}
      >
        <AccordionSWARow amount={48.0} label="Claimable to date" style={{ marginRight: '1rem' }} />
      </AccordionSummary>
      <AccordionDetails style={{ padding: '0rem 1rem 1.25rem 1rem', margin: '0rem' }}>
        <div style={{ margin: '0rem 0rem 1.5rem 0rem', display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
          <AccordionSWARow amount={48.0} label="Tokens claimed" />
          <AccordionSWARow amount={48.0} label="Total allocation" />
          <AccordionSWARow amount={48.0} label="Unvested" />
          <div
            style={{
              color: 'var(--RED, #FF4057)',
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <span>Fee (1%)</span>
            <span>{formatUsdValue(0.05, { minimumFractionDigits: 2 })}</span>
          </div>
        </div>
        <AccordionSWARow amount={52520} label="Next unlock amount" />
      </AccordionDetails>
    </Accordion>
  )
}
