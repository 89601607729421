import clsx from 'clsx'
import { type ComponentProps, useRef } from 'react'

export interface AmountInputProperties extends Omit<ComponentProps<'input'>, 'onChange'> {
  onChange?: (value: string) => void
  error?: string
  decimals?: number
}

export const AmountInput = ({
  onChange,
  value,
  error,
  decimals = 18,
  placeholder,
  className,
  readOnly,
  ...rest
}: AmountInputProperties) => {
  const ref = useRef<HTMLInputElement | null>(null)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value.replaceAll(/[^\d.]|(?<=\..*)\./g, '')
    if (inputValue.startsWith('.')) inputValue = `0${inputValue}`
    const [whole, decimal] = inputValue.split('.')
    if (decimal?.length > decimals) inputValue = `${whole}.${decimal.slice(0, decimals)}`
    onChange?.(inputValue.replace(/^0+/, '0'))
  }

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }} className={clsx('size-full', className)}>
      {!readOnly && (
        <input
          type="text"
          style={{
            border: 'none',
            backgroundColor: 'transparent',
            outline: 'none',
            alignContent: 'center',
            verticalAlign: 'middle',
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            padding: '0',
            fontSize: '1.75rem',
            fontWeight: '550',
            letterSpacing: '0.0625rem',
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          {...rest}
          ref={ref}
          placeholder={placeholder ?? '0.0'}
          onChange={handleChange}
          value={value}
        />
      )}
    </div>
  )
}

AmountInput.displayName = 'AmountInput'
